<template>
  <v-card
    color="white"
    class="rounded px-4"
    :class="[smAndDown ? 'py-4' : 'py-8']"
  >
    <div class="co-flex-col co-justify-center co-items-center mb-2">
      <span
        :class="[smAndDown ? 'text-h6 my-2' : 'text-h5 my-4']"
      >{{ title }}</span>
    </div>
    <v-stepper
      v-model="steps.value"
      alt-labels
      flat
    >
      <v-stepper-header>
        <v-stepper-step
          :step="steps.person.step"
          :complete="steps.value > 1"
        >
          {{ steps.person.text }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          :step="steps.org.step"
          :complete="steps.value > 2"
        >
          {{ steps.org.text }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          :step="steps.other.step"
          :complete="steps.other.complete"
        >
          {{ steps.other.text }}
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <div class="co-flex-col co-justify-center co-items-center">
            <div
              class="co-flex-col"
              :class="[smAndDown ? 'co-w240 my-3' : 'co-w300 my-6']"
            >
              <span class="co-text-sm mb-2">
                <span class="primary--text">*</span>
                个人基本资料
              </span>
              <v-text-field
                v-model="frmData.realname"
                label="真实姓名"
                outlined
                dense
              />
            </div>
            <v-btn
              height="40"
              width="200"
              color="primary"
              :loading="btnPerson.loading"
              @click="toOrg"
            >
              {{ btnPerson.text }}
            </v-btn>
          </div>
        </v-stepper-content>
        <v-stepper-content step="2">
          <div class="co-flex-col co-justify-center co-items-center">
            <div
              class="co-flex-col  "
              :class="[smAndDown ? 'co-w240 my-3' : 'co-w300 my-6']"
            >
              <span class="co-text-sm mb-2">
                <span class="primary--text">*</span>
                单位/学校信息
              </span>

              <template v-if="smAndDown">
                <v-select
                  v-model="frmData.orgProv"
                  label="所在省份"
                  :items="orgProvs"
                  item-text="label"
                  item-value="value"
                  dense
                  outlined
                  @change="onOrgProvChange"
                />
                <v-select
                  v-model="frmData.organizationAddress"
                  label="所在城市"
                  :items="cityItems"
                  item-text="label"
                  item-value="value"
                  dense
                  outlined
                />
              </template>
              <template v-else>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                  >
                    <v-select
                      v-model="frmData.orgProv"
                      label="所在省份"
                      :items="orgProvs"
                      item-text="label"
                      item-value="value"
                      dense
                      outlined
                      @change="onOrgProvChange"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                  >
                    <v-select
                      v-model="frmData.organizationAddress"
                      label="所在城市"
                      :items="cityItems"
                      item-text="label"
                      item-value="value"
                      dense
                      outlined
                    />
                  </v-col>
                </v-row>
              </template>

              <v-text-field
                v-model="frmData.organizationName"
                label="所属单位/学校"
                outlined
                dense
              />
              <v-text-field
                v-model="frmData.organizationDepartment"
                label="所属科室/专业"
                outlined
                dense
              />
            </div>
            <v-btn
              height="40"
              width="200"
              color="primary"
              :loading="btnOrg.loading"
              @click="toOther"
            >
              {{ btnOrg.text }}
            </v-btn>
          </div>
        </v-stepper-content>
        <v-stepper-content step="3">
          <div class="co-flex-col co-justify-center co-items-center">
            <div
              class="co-flex-col"
              :class="[smAndDown ? 'co-w240 my-3' : 'co-w300 my-6']"
            >
              <span class="co-text-sm mb-2">
                选填信息（点击完成可跳过）
              </span>
              <v-text-field
                v-model="frmData.address"
                label="收件地址"
                outlined
                dense
              />
              <v-text-field
                v-model="frmData.email"
                label="邮箱"
                outlined
                dense
              />
              <v-text-field
                v-model="frmData.hobby"
                label="兴趣"
                outlined
                dense
              />
              <!-- <v-text-field
                v-model="frmData.hobby"
                label="兴趣标签"
                outlined
                dense
                prepend-inner-icon="mdi-lock-outline"
              /> -->
            </div>
            <v-btn
              height="40"
              width="200"
              color="primary"
              :loading="btnOther.loading"
              @click="toConfirm"
            >
              {{ btnOther.text }}
            </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <dialog-alert ref="dlgAlert" />
  </v-card>
</template>

<script>
  import api from '@/api/co.api'
  import user from '@/api/co.user'
  import lib from '@/api/co.lib'

  import provs from '@/api/city-data/province'
  import citys from '@/api/city-data/city'

  export default {
    components: {
      DialogAlert: () => import('@/pages/sections/comm/DialogAlert.vue')
    },
    data () {
      return {
        title: '完善个人信息',
        frmData: {
          realname: '',
          mobile: '',
          code: '',
          organizationAddress: '',
          organizationName: '',
          organizationDepartment: '',
          address: '',
          email: '',
          hobby: '',
          prov: '',
          city: '',
          orgProv: '',
          orgCity: ''
        },
        btnPerson: {
          loading: false,
          text: '下一步'
        },
        btnOrg: {
          loading: false,
          text: '下一步'
        },
        btnPwd: {
          loading: false,
          text: '下一步'
        },
        btnOther: {
          loading: false,
          text: '完成'
        },
        steps: {
          value: 1,
          person: {
            step: 1,
            name: 'person',
            text: '个人基本资料',
            complete: false
          },
          org: {
            step: 2,
            name: 'org',
            text: '单位/学校信息',
            complete: false
          },
          other: {
            step: 3,
            name: 'other',
            text: '选填信息',
            complete: false
          }
        },
        txtCode: {
          timer: null,
          text: '获取验证码',
          color: 'primary',
          disabled: false,
          count: 0
        },
        provItems: provs,
        cityItems: [],
        orgProvs: provs,
        orgCitys: [],
        userInfo: {}
      }
    },
    computed: {
      smAndDown () {
        return this.$vuetify.breakpoint.smAndDown
      }
    },
    created () {
      this.getUserInfo()
    },
    methods: {
      showAlert (type, message) {
        const dlg = this.$refs.dlgAlert
        if (dlg) {
          dlg.show({
            type,
            message
          })
        }
      },
      setInfo (val) {
        this.frmData.realname = val.realname
        this.frmData.organizationName = val.organizationName
        this.frmData.organizationDepartment = val.organizationDepartment
        this.frmData.address = val.address
        this.frmData.email = val.email
        this.frmData.hobby = val.hobby
      },
      getUserInfo () {
        const me = this
        const executed = function (res) {
          // console.log({ res })
          if (res.status) {
            me.userInfo = res.data
            me.setInfo(me.userInfo)
          }
        }

        user.info.gain({
          executed
        })
      },
      getProvIndex (provCode) {
        let result = -1
        for (const i in provs) {
          const src = provs[i]
          if (src.value === provCode) {
            result = parseInt(i, 10)
            break
          }
        }
        return result
      },
      setCityItems (provCode) {
        const provInd = this.getProvIndex(provCode)
        if (provInd >= 0) {
          this.cityItems = citys[provInd]
          const first = this.cityItems[0]
          this.frmData.city = first.value
        }
      },
      setOrgCitys (provCode) {
        const provInd = this.getProvIndex(provCode)
        if (provInd >= 0) {
          this.cityItems = citys[provInd]
          const first = this.cityItems[0]
          this.frmData.organizationAddress = first.value
        }
      },
      onProvChange (val) {
        // console.log({ val })
        this.setCityItems(val)
      },
      onOrgProvChange (val) {
        this.setOrgCitys(val)
      },
      clearCodeTimer () {
        if (this.txtCode.timer) {
          clearInterval(this.txtCode.timer)
          this.txtCode.timer = null
        }
      },
      startCodeTimer () {
        const me = this
        const decreaseCount = function () {
          me.txtCode.count -= 1
          if (me.txtCode.count < 1) {
            me.clearCodeTimer()
            me.txtCode.text = '获取验证码'
            me.txtCode.disabled = false
            me.txtCode.color = 'primary'
          } else {
            me.txtCode.text = `(${me.txtCode.count}S)后重发`
          }
        }

        this.txtCode.count = 60
        this.txtCode.disabled = true
        this.txtCode.color = 'grey'
        this.txtCode.timer = setInterval(decreaseCount, 1000)
      },
      sendMsgCode () {
        const me = this
        const executing = function () {
          me.startCodeTimer()
        }

        const executed = function (res) {
          // console.log('sendMsgCode, res: %o', res)
          if (res.status) {
            me.$message({
              message: '验证码发送成功！',
              type: 'success'
            })
          } else {
            me.$message({
              message: '验证码发送失败！',
              type: 'error'
            })
          }
        }

        lib.sso.sendMsgCode({
          mobile: this.frmData.mobile,
          executing,
          executed
        })
      },
      setRealname () {
        const me = this
        const data = {
          realname: this.frmData.realname,
        }

        const executing = function () {
          me.btnPerson.loading = true
        }

        const executed = function (res) {
          me.btnPerson.loading = false
          if (res.status) {
            me.steps.value = 2
            me.$notify({
              title: '成功提示',
              message: '个人基本资料设置成功！',
              type: 'success'
            })
          } else {
            const message = `错误：${res.data.code} - ${res.data.desc}`
            me.$notify({
              title: '个人基本资料设置失败',
              message,
              type: 'error'
            })
          }
        }

        user.info.update({
          data,
          executing,
          executed
        })
      },
      bindMobile () {
        const me = this
        const executing = function () {
          me.btnMobile.loading = true
        }

        const executed = function (res) {
          me.btnMobile.loading = false
          if (res.status) {
            me.setRealname()
            me.steps.value = 2
            me.$notify({
              title: '成功提示',
              message: '绑定手机号码成功！',
              type: 'success'
            })
          } else {
            const message = `错误：${res.data.code} - ${res.data.desc}`
            me.$notify({
              title: '绑定手机号码失败',
              message,
              type: 'error'
            })
          }
        }

        lib.sso.bindMobile({
          mobile: this.frmData.mobile,
          code: this.frmData.code,
          executing,
          executed
        })
      },
      setOrgInfo () {
        const me = this
        const data = {
          organizationAddress: this.frmData.organizationAddress,
          organizationName: this.frmData.organizationName,
          organizationDepartment: this.frmData.organizationDepartment
        }

        const executing = function () {
          me.btnOrg.loading = true
        }

        const executed = function (res) {
          me.btnOrg.loading = false
          if (res.status) {
            me.steps.value = 3
            me.$notify({
              title: '成功提示',
              message: '单位信息设置成功！',
              type: 'success'
            })
          } else {
            const message = `错误：${res.data.code} - ${res.data.desc}`
            me.$notify({
              title: '单位信息设置失败',
              message,
              type: 'error'
            })
          }
        }

        user.info.update({
          data,
          executing,
          executed
        })
      },
      resetUserInfo () {
        const me = this
        const executed = function (res) {
          // console.log({ res })
          if (res.status) {
            user.info.save(res.data)
          }

          api.page.navigate(me, 'home')
        }

        user.info.gain({
          executed
        })
      },
      setOther () {
        const me = this
        const data = {
          address: this.frmData.address,
          email: this.frmData.email,
          hobby: this.frmData.hobby
        }

        const executing = function () {
          me.btnOther.loading = true
        }

        const executed = function (res) {
          me.btnOther.loading = false
          if (res.status) {
            me.steps.other.complete = true
            me.$notify({
              title: '成功提示',
              message: '选填信息设置成功！',
              type: 'success'
            })
            me.resetUserInfo()
          } else {
            const message = `错误：${res.data.code} - ${res.data.desc}`
            me.$notify({
              title: '选填信息设置失败',
              message,
              type: 'error'
            })
          }
        }

        user.info.update({
          data,
          executing,
          executed
        })
      },
      toOrg () {
        this.setRealname()
      },
      toOther () {
        if (!this.frmData.organizationName) {
          this.showAlert('error', '请填写单位/学校')
          return
        }

        if (!this.frmData.organizationDepartment) {
          this.showAlert('error', '请填写科室/专业')
          return
        }
        this.setOrgInfo()
      },
      toConfirm () {
        this.setOther()
      }
    }
  }
</script>
